import React from "react"

import DocumentLayout from "../../components/layout/document"

export default () => {
  return (
    <DocumentLayout title="Fee Object">
      <div className="headline">
        <h1 className="text-primary m-0">Fee Object</h1>
      </div>
      <div className="items-group">
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">type</span>
            <span className="field-type block-md">String</span>
          </div>
          <div className="col-md-9">
            <p>
              Value fixed to<span className="reference">fee</span>, represents fee
              object
            </p>
          </div>
        </div>
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">fee_currency_code</span>
            <span className="field-type block-md">3 letter currency code</span>
          </div>
          <div className="col-md-9">
            <p>Currency code of the fee</p>
          </div>
        </div>
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">fee_amount</span>
            <span className="field-type block-md">Number with 2 decimal</span>
          </div>
          <div className="col-md-9">
            <p>Amount of fee</p>
          </div>
        </div>
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">fee_name</span>
            <span className="field-type block-md">String</span>
          </div>
          <div className="col-md-9">
            <p>Name of fee</p>
          </div>
        </div>
        <div className="row item">
          <div className="col-md-3 text-md-right">
            <span className="field-name">fee_description</span>
            <span className="field-type block-md">String</span>
          </div>
          <div className="col-md-9">
            <p>Description of fee</p>
          </div>
        </div>
      </div>
    </DocumentLayout>
  )
}
